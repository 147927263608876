
import {defineComponent, onMounted, ref} from "vue";
import QuicksightService from "@/core/services/QuicksightService";
import {createEmbeddingContext} from "amazon-quicksight-embedding-sdk";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Show",
  components: {EntityLoading},
  props: {
    code: {}
  },
  setup(props) {
    const title = ref('');
    const loading = ref(true);

    const reload = async (code) => {
      const embeddingContext = await createEmbeddingContext({
        onChange: (changeEvent, metadata) => {
          console.log('Context received a change', changeEvent, metadata);
        },
      });
      console.log("get quicksight with code ", code)
      loading.value = true;
      QuicksightService.getCode(code).then(res => {
        title.value = res.name;
        const id = document.getElementById("quick-sight-id");
        const frameOptions: any = {
          url: res.url,// replace this value with the url generated via embedding API
          container: id,
          height: res.height + "px",
        };
        const contentOptions = {
          locale: "en-US",
          toolbarOptions: {
            export: false,
            undoRedo: false,
            reset: false
          },
          attributionOptions: {
            overlayContent: false,
          },
        };
        embeddingContext.embedDashboard(frameOptions, contentOptions);
        setCurrentPageBreadcrumbsWithParams('Report', [
          {link: false, router: '', text: res.name}
        ])
      }).finally(() => {
        loading.value = false;
      })
    }
    onMounted(() => {
      reload(props.code);
    })
    return {
      loading,
      title,
      reload,
    }
  },
  watch: {
    code(newCode) {
      this.reload(newCode);
    }
  }
})
